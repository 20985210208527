import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BlogMeta } from "../../components/blogMeta";

export default function BlogPostSimpleCaseForSearchEngineAds() {
  return (
    <Layout>
      <SEO
        title="The Simplest Case for Search Engine Ads | Primespot Blog"
        description="While SEO is great, search engine ads are extremely important too."
      />
      <main>
        <h1>
          Dear Manager Obsessed with SEO, the Simplest Case for Search Engine
          Ads
        </h1>
        <BlogMeta author="Primespot Marketing" published="May 20, 2020" tags={["Marketing"]} />
        <p>
          Did I call you out with this headline? If so, then I would like to
          share my perspective with you.
        </p>
        <p>
          Far too many business owners, managers, or executives are absolutely
          OBSESSED with SEO. Don’t get me wrong, SEO is great.
        </p>
        <p>But…</p>
        <p>
          It’s too easy to get drunk off of the sweet nectar of the promise of constant
          free website traffic.
        </p>
        <p>
          First I’m going to list a few problems with an over-reliance on SEO.
          Then I’m going to explain how search engine ads can solve many of
          these issues.
        </p>
        <p>
          But before either of those things, I need to state one cold-hard fact:
        </p>
        <p>
          Like investing, your marketing efforts need to be diversified. If all
          your eggs are in the SEO basket, you are vulnerable. This is true for
          any form of marketing--not just SEO.
        </p>
        <p>
          But with SEO, you are particularly vulnerable. This is because the
          rules of the game are completely controlled by Google and can change
          at a moment’s notice leaving you in the lurch.
        </p>
        <p>Don’t allow yourself to be this vulnerable!</p>
        <p>With that said, onwards to the risks of over-reliance on SEO.</p>
        <ul>
          <li>
            As stated in the preceding section, Google controls the game. Not
            you.
          </li>
          <li>
            Your competitors have just as much to say about your success as you
            do. Just because you’re investing in SEO doesn’t mean you will be
            successful. If your competitors are also investing, you can get
            stuck in an epic--but expensive--battle for every position rank.
          </li>
          <li>
            It may not work. There is absolutely no guarantee of success with
            SEO. Sometimes the investment is wasted.
          </li>
          <li>
            It can be insanely expensive to move up through the more competitive
            ranks. You might be caught relying on link building to move up. Link
            building can be difficult, time consuming, and costly.
          </li>
          <li>
            It can take a long time to get results that turn into revenue.
            Moving up positions doesn’t always directly correlate with more
            traffic and revenue. Sometimes jumping positions yields no
            additional traffic. It isn’t unusual for SEO efforts to take six
            months or even longer for revenue to be impacted.
          </li>
        </ul>
        <p>
          Many of these issues are solved by investing a modest amount of money
          into clicks through search engine advertisements.
        </p>
        <ul>
          <li>
            Google still controls the game, but they are far more transparent
            about it.
          </li>
          <li>
            As long as your account is set up correctly and you’re bidding
            enough for a click, you will definitely be visible. It will work
            every time.
          </li>
          <li>
            You pay only for the clicks. No steep investments to get to the top
            spot. If you want the top spot, bid for it and pay only once you get
            traffic.
          </li>
          <li>
            Instant success. No more six month wait periods to see if your
            investment pays off. You can be at the top of page one on day one if
            you want.
          </li>
          <li>
            The costs are predictable and can be budgeted. This is especially
            true once you have enough data to determine the conversion rates of
            your landing pages. Once this is the case, budgeting is just a
            simple formula.
          </li>
        </ul>
        <p>
          SEO is definitely a big part of what I do. Despite that, I ALWAYS
          recommend coupling SEO with paid advertisements.
        </p>
      </main>
    </Layout>
  )
}
